import React from 'react'
import ArticalCard from '../ArticalCard/ArticalCard'

const SectionCard = ({ props }) => {
	return (
		<div className='w-full border sectionCard border-y-black-100'>
			<h1 className='py-20 text-4xl font-bold text-center mmd:py-16 primary-color'>{props.title}</h1>
			<div className='flex justify-center pb-20 sectionCardTextInfoMain mlg:p-[0_1.2rem_5rem_1.2rem]'>
				<div className='w-1/3 text-lg text-center sectionCardTextInfo mlg:w-[90%] msm:w-full'>
					<p>{props.text1}</p>
					<p>{props.text2}</p>
					<p>{props.text3}</p>
				</div>
			</div>
			<div className='flex justify-center pb-20'>
				<img className='w-44' src={props.imgUrl} alt={props.title} />
			</div>
			<div className='flex justify-center pb-20'>
				<div className='articalCardsWrapper [@media(max-wdith("1144px"))]:max-w-[96%]  grid grid-cols-3 gap-10 mx-auto max-w-[1100px] mlg:grid-cols-2 m680:grid-cols-1 w-full'>
					{props.articalData.map((d, i) => (
						<ArticalCard props={d} key={i} />
					))}
				</div>
			</div>
		</div>
	)
}

export default SectionCard
