import { faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const BePartOfDnn = () => {
	return (
		<div className='bg-[#0f70e7] pt-32 pb-24 text-center mmd:p-[6rem_1.2rem_4rem_1.2rem]'>
			<div className='flex justify-center mb-8'>
				<h3 className='text-[22px] text-white px-5 leading-[40px] border-[3px] font-bold w-72'>
					Be a part of NEWSENGINE
				</h3>
			</div>
			<h3 className='mt-5 mb-5 text-4xl font-bold text-black'>PUTTING INDEPENDENCE BACK INTO </h3>
			<h3 className='mt-5 mb-5 text-6xl font-bold text-black'> NEWS MEDIA</h3>
			<div className='text-lg font-medium'>
				<p className={`text-white mmd:text-[1.8rem] mmd:first-of-type:m-0 mt-10 `}>
					We believe that NEWSENGINE can be the next step to bringing integrity and <br /> independence back into
					the news, so readers can all just focus on <br /> the bare facts, free of tampering and misdirection.
					Feel free to ask <br className={`mmd:hidden`} /> us anything on the channels below!
				</p>
			</div>
			<div className='flex justify-center mt-10'>
				<div className='flex justify-between w-44'>
					<div className='social-icon'>
						<a
							href='/#'
							className='discord-icon bg-white w-16 h-16 rounded-[4rem] flex justify-center items-center'>
							<FontAwesomeIcon className={`text=[#06435f] text-[1.8rem]`} icon={faDiscord} />
						</a>{' '}
					</div>
					<div className='social-icon'>
						<a
							href='https://t.me/newsengineAI'
							className='discord-icon bg-white w-16 h-16 rounded-[4rem] flex justify-center items-center'>
							<FontAwesomeIcon className={`text=[#06435f] text-[1.8rem]`} icon={faTelegram} />{' '}
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BePartOfDnn
