import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelegram } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { TwitterShareButton, TelegramShareButton } from 'react-share'
import decentralized from '../../assets/decentralized.png'
import factual from '../../assets/factual.png'
import collaborative from '../../assets/collaborative.png'

const IntroDdn = () => {
	return (
		<div className='intro-container overflow-hidden pt-20 bg-[#005aff] w-full text-white'>
			<div className='flex justify-center pb-10'>
				<p className='intro-title border-[3px] text-white text-[22px] msm:text-[19px] tracking-[0.14em] font-bold leading-[1] py-2.5 px-5 w-70'>
					Introducing NEWSENGINE
				</p>
			</div>

			<div className='flex justify-center'>
				<div className='w-1/2 text-center headingWrapper mlg:w-[96%] mx-auto'>
					<h1 className='text-5xl font-bold leading-[1.3] mlg:text-4xl'>NEWS BY THE PEOPLE,</h1>
					<h2 className='text-5xl font-bold mlg:text-4xl'>FOR THE PEOPLE.</h2>
					<p className='pt-10 text-lg font-semibold mlg:pt-[2rem]'>
						NEWSENGINE, is a <strong>Decentralized</strong>, news platform, combining news creation with
						decentralized networks to deliver factual content, curated by the community.
					</p>
				</div>
			</div>

			<div className='flex justify-center mt-24 introBoxesWrapper msm:w-[96%] msm:m-[4rem_auto_0_auto] mb-28'>
				<div className='flex justify-around  sm:w-full sm:max-w-[558px] mx-auto intro-boxes msm:w-full msm:grid  msm:grid-cols-3 msm:gap-[30px]'>
					<div className='w-48 msm:w-full'>
						<div className='flex justify-center'>
							<img className='w-16 msm:w-full msm:max-w-[3rem]' src={decentralized} alt='' />
						</div>
						<h3 className='mt-5 text-xl font-bold text-center msm:text-[12.8px]'>DECENTRALIZED</h3>
					</div>
					<div className='w-48 msm:w-full'>
						<div className='flex justify-center'>
							<img className='w-16 msm:w-full msm:max-w-[3rem]' src={factual} alt='' />
						</div>
						<h3 className='mt-5 text-xl font-bold text-center msm:text-[12.8px]'>FACTUAL</h3>
					</div>
					<div className='w-48 msm:w-full'>
						<div className='flex justify-center'>
							<img className='w-16 msm:w-full msm:max-w-[3rem]' src={collaborative} alt='' />
						</div>
						<h3 className='mt-5 text-xl font-bold text-center msm:text-[12.8px]'>COLLABORATIVE</h3>
					</div>
				</div>
			</div>

			<div className='flex justify-center mt-24 mb-24 msm:m-[4rem_auto_20px_auto] videoMain mmd:px-2.5 mx-auto mmd:m-[0_auto_40px_auto]'>
				<div className='flex justify-around w-[640px] h-[360px] mmd:relative videoWrapper mmd:h-0.5 mmd:w-full mmd:max-w-[600px] mmd:pb-[56.25%]'>
					{/* <div className='thumbnail-des'>
            <img src={videoPlay} className='video-play' />
          </div>
          <div>
            <div className='thumbnail-man'><div ><img className='video-play' src={videoPlay} /></div></div>
          </div> */}

					<iframe
						className={`w-full h-full mmd:absolute mmd:top-0 mmd:left-0 mmd:w-full mmd:h-full`}
						width='640'
						height='360'
						src='https://www.bitchute.com/embed/yIHlyYngEMj0/'
						title='Video Player'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowfullscreen></iframe>
				</div>
			</div>

			<div className='flex justify-center shareVideoMain'>
				<div className='w-38'>
					<h3 className='text-3xl font-bold mt-18'>SHARE THE VIDEO</h3>
					<div className='flex justify-around mt-10 mb-20 text-6xl'>
						<TelegramShareButton url='https://www.bitchute.com/embed/yIHlyYngEMj0/' quote={'Share on Telegram'}>
							<FontAwesomeIcon icon={faTelegram} />
						</TelegramShareButton>
						<TwitterShareButton url='https://www.bitchute.com/embed/yIHlyYngEMj0/' quote={'Share on Twitter'}>
							{' '}
							<FontAwesomeIcon icon={faTwitter} />
						</TwitterShareButton>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IntroDdn
