import React from 'react'
import DdnImg from '../../assets/header-text.png'
import slacklogo from '../../assets/slack-logo.png'

const DdnHere = () => {
	return (
		<div className={`ddn_container m890:min-h-[auto] bg-cover relative top-0 left-0 w-full min-h-[50rem] `}>
			<div className={`w-full h-full absolute top-0 left-0 z-[1] opacity-70 bg-[#0080ff]`}></div>
			<div className='map bg-contain absolute top-[8%] bg-[center_center] left-0 w-[80%] h-[80%] bg-no-repeat z-[1] opacity-70'></div>
			<div
				className={`text-container m890:max-w-[96%] m890:p-[40px_20px_0_20px] pt-24 px-[50px] text-center w-full text-white items-center flex flex-col mx-auto max-w-[1800px]`}>
				<h1 className='text-5xl font-bold z-[3] m890:min-h-[auto] m890:text-[2.2rem]'>
					News by the people, for the people.
				</h1>
				<img className='w-80 !z-[3]' src={DdnImg} alt='DDN' />
				<div className='flex !z-[3] flex-col items-center'>
					<a
						href='/#'
						className={`try-dnn-button m890:text-[17.6px] m890:h-[50px] m890:max-w-[16rem] !z-[3] mb-5 rounded-[30px] text-white text-[17.6px] font-bold h-[60px] tracking-[1.28px] max-w-[288px] w-full bg-[#0008ff] flex justify-center items-center`}>
						HOST NEWSENGINE
					</a>
					<div className='flex justify-between ddn-buttons m-[1rem_0_2rem_0]'>
						<a
							href='https://t.me/newsengineAI'
							className={`flex items-center bg-white border-2 border-white text-black text-[12.8px] align-middle scale-[.95] p-[0.8rem_1.6rem] tracking-[0.12em] rounded-[2rem] icons`}>
							{' '}
							<img className='w-5 mr-1' src={slacklogo} alt='slack' /> TELEGRAM
						</a>
						<a
							className={` bg-white border-2 border-white text-black text-[12.8px] align-middle scale-[.95] p-[0.8rem_1.6rem] tracking-[0.12em] rounded-[2rem]`}
							href='/#'>
							SUBSCRIBE
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DdnHere
