import React from 'react'

const TodaysNews = () => {
	return (
		<div className='relative flex justify-center px-4 py-8 bg-black bg-center bg-no-repeat bg-cover news-container'>
			<p className='w-1/2 text-xl mlg:w-full mlg:p-0 mlg:text-lg font-semibold text-white relative z-[1] text-center pt-28 pb-28'>
				Today's news is plagued by sensationalistic headlines and misinformation, resulting in fake news and
				unbalanced reporting. NEWSENGINE provides the blueprint to a more open world for creating and consuming the
				news, while ensuring accuracy through incentivization.
			</p>
			<div className='absolute top-0 left-0 z-0 w-full h-full overlay bg-black/50'></div>
		</div>
	)
}

export default TodaysNews
