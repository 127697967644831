import React from 'react'
import slacklogo from '../../assets/slack-logo.png'

const KeepInTouch = () => {
	return (
		<div className='text-center text-white bg-black mmd:pt-28 mmd:pb-20 pt-36 pb-28'>
			<p>LET'S MAKE THE NEWS MORE OPEN. TOGETHER.</p>
			<h1 className='mt-5 mb-5 text-6xl font-bold'>KEEP IN TOUCH!</h1>
			<p className='primary-color-text'>Join the conversation on our Telegram channel or</p>
			<p>
				<span className='primary-color-text'>email us at </span>
				<a href='/#'>newsengine@protonmail.com</a>
			</p>
			<br />
			<br />
			<div className='flex justify-center mb-10'>
				<a
					href='https://t.me/newsengineAI'
					className='flex text-black gap-2.5 border-2 hover:border-blue-500  border-white hover:text-white duration-300 transition-colors bg-white font-inter text-[.9rem] font-bold px-6 py-3.5 align-middle rounded-3xl'>
					{' '}
					<img className='w-5' src={slacklogo} alt='slack' />
					<span className={`text-black`}>TELEGRAM</span>
				</a>
			</div>
			<p>NEWSENGINE LLC</p>
			<p className='mb-5'>© Copyright 2025. All Rights Reserved.</p>
		</div>
	)
}

export default KeepInTouch
