import React from 'react'
import ourMission from '../../assets/mission-alt.png'

const OurMission = () => {
	return (
		<div className='ourMissionMain mmd:pt-20 mmd:px-[1.2rem] mmd:pb-0 text-center pt-20 border border-y-black-100'>
			<h1 className='text-6xl font-bold text-[#005aff] mmd:text-[3rem]'>OUR MISSION</h1>
			<div className='mt-10 mb-10 text-xl mmd:text-base'>
				<p>
					NEWSENGINE will focus exclusively on <strong>factual</strong> and thorough perspectives of{' '}
					<br className={`mmd:hidden`} /> current affairs, while rewarding all parties involved.
				</p>
				<p className='mt-4 mmd:text-base'>
					Our mission is to create news content that is both <strong>empowering</strong> for{' '}
					<br className={`mmd:hidden`} /> its audience, as well as representative of the integrity of its writers.{' '}
					<br className={`mmd:hidden`} /> We aspire to become the most <strong>trusted and democratic</strong> news{' '}
					<br className={`mmd:hidden`} /> alternative to mainstream media.
				</p>
			</div>
			<div className='flex justify-center pb-24'>
				<img className='w-56' src={ourMission} alt='ourmission' />
			</div>
		</div>
	)
}

export default OurMission
