import React from 'react'
import img1 from '../../assets/whitepaper-alt.png'

const WhitePaper = () => {
	return (
		<div className='whitePaperMain m680:p-[4.5rem_1.2rem] border border-y-black-100 pt-24 pb-24 text-center'>
			<h1 className='text-6xl font-bold m680:text-5xl primary-color'>WHITE PAPER</h1>
			<div className='text-lg font-medium m680:[&>br]:hidden text-center primary-color-text mt-10 mb-10'>
				<p>
					Interested in knowing more and getting an in-depth view of how <br />
					NEWSENGINE works? Send us an email to get access to our <br />
					<strong>white paper!</strong>
				</p>
			</div>

			<div className='flex justify-center'>
				<a
					href='/#'
					className='white-paper-button p-[1.2rem_2.5rem] rounded-[29px] mb-20 bg-transparent text-[#0008ff] border-2 border-[#0008ff] transition-colors duration-300 hover:bg-[#0008ff] hover:text-white'>
					READ WHITE PAPER
				</a>
			</div>

			<div className='flex justify-center'>
				<img className='w-56' src={img1} alt='article-flow' />
			</div>
		</div>
	)
}

export default WhitePaper
