import React from 'react'

const ArticalCard = ({ props }) => {
	return (
		<div className='w-full text-center'>
			<div className='mb-2 border-[0.2rem] border-white text-white bg-[#0f70e7] w-14 h-14 rounded-[1.75rem] text-center font-inter text-[1.9rem] mx-auto pt-1'>
				{props.number}
			</div>
			<h3 className='mb-4 text-xl font-bold text-[#48525d]'>{props.title}</h3>
			<p>{props.details}</p>
		</div>
	)
}

export default ArticalCard
