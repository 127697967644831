import React from 'react'
import img1 from '../../assets/article-flow.png'
import img2 from '../../assets/reward-flow.png'

const HowItWorks = () => {
	return (
		<div className='pt-24 pb-24 border border-y-black-100'>
			<h1 className='mb-20 text-6xl font-bold text-center howItWorksHeading m680:text-5xl m680:mb-12 primary-color'>
				HOW IT WORKS
			</h1>
			<div className='howItWorksTextInfo m680:px-[1.2rem] text-lg font-medium text-center primary-color-text mb-24'>
				<p>
					Simply put, writers produce and submit content, which is inspected <br className={`m680:hidden`} /> for{' '}
					<strong>accuracy</strong> by a randomized set of reviewers together with AI on the network.
				</p>
				<p className='mt-6'>
					Articles deemed acceptable enough (as per NEWSENGINE's guidelines) will <br className={`m680:hidden`} />{' '}
					be <strong>published</strong> to the network and made available to audience.
				</p>
			</div>
			<div className='flex justify-center mb-24 cardWrapper m680:flex-col m680:items-center'>
				<div className='secondary-bg'>
					<img src={img1} alt='article-flow' />
				</div>
				<div className='primary-bg'>
					<img src={img2} alt='reward-flow' />
				</div>
			</div>
			<div className='howItWorksTextInfo m680:px-[1.2rem] text-lg font-medium text-center primary-color-text'>
				<p>
					Each participant on NEWSENGINE is <strong>rewarded</strong> for their contribution, in the{' '}
					<br className={`m680:hidden`} /> form of NEWSENGINE <strong>tokens</strong>
					. Writers earn a reward each time an article is <br className={`m680:hidden`} /> accepted by the majority
					of assigned reviewers. Reviewers earn a <br className={`m680:hidden`} /> reward by accepting or rejecting
					articles based on their degree of <br className={`m680:hidden`} /> accuracy. Lastly, readers earn a
					reward for readership and other <br className={`m680:hidden`} /> engaging activities, such as commenting.
				</p>
			</div>
		</div>
	)
}

export default HowItWorks
